import styled, { css } from "styled-components"

export const Wrapper = styled.div`
  ${props =>
    props.background === "dark" &&
    css`
      background-color: #2c3036;
    `}
  ${props =>
    props.background === "green" &&
    css`
      background-color: #18373d;
    `}
  ${props =>
    props.background === "light" &&
    css`
      background-color: #af8f7b;
    `}
`
